import { RepeatIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    HStack,
    Text,
    useDisclosure,
    useToast,
    VStack,
    Wrap } from '@chakra-ui/react';
import React, { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    usePublishAllGarmentMutation,
    usePublishAllModelMutation,
    usePublishGarmentMutation,
    usePublishModelMutation,
} from '../../services/api/api-publish';
import {
    getConfig,
    getExperience,
    getOutfitGender,
    getOutfitPose,
    resetOutfit,
    setBottom,
    setDress,
    setModel,
    setOuterwear,
    setOutfitGender,
    setOutfitPose,
    setPose,
    setTop,
} from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { FacetTermsData, Garment, GarmentResponse, Model, ModelsResponse } from '../../types/api-types';
import isModel from '../../types/type-guards';
import { PRODUCT_ACTIONS, PRODUCT_TYPES, PRODUCTS_CATEGORIES, PRODUCTS_LOCALES } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import FiltersContext from '../filters/FiltersContext';
import FiltersPanel from '../filters/FiltersPanel';
import { FilterInContextType, Filters } from '../filters/filtersTypes';
import Modal from '../Modal';
import ProductsContext from './ProductsContext';
import ProductsGrid from './ProductsGrid';
import { SelectedProduct } from './ProductTypes';

interface ProductsLayoutProps {
    category: PRODUCTS_CATEGORIES;
    productTypes: FacetTermsData[];
    queryResponse?: GarmentResponse | ModelsResponse;
    isLoading: boolean;
    dataQuery(filters?: Filters, query?: string, page?: number, collapse?: number | null, forceRefetch?: boolean): void;
    customHeader?: ReactElement;
    hideContent?: boolean;
    clientId: string;
    children?: ReactNode;
    lockFrontPose?: boolean;
}

let timeoutSearchId: ReturnType<typeof setTimeout> | null = null;

const ProductsLayout = React.forwardRef<{resetData:(keepFilter: boolean) => void}, ProductsLayoutProps>((
    { category, productTypes, queryResponse, isLoading, dataQuery, customHeader, hideContent, clientId, children, lockFrontPose }, ref,
) => {
    const isModelLayout = category === 'model';
    const toast = useToast();
    const { t } = useTranslation(PRODUCTS_LOCALES);
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();

    const outfitPoseStore = useAppSelector((state) => getOutfitPose(state));
    const outfitGenderStore = useAppSelector((state) => getOutfitGender(state));

    const selectedExperience = useAppSelector((state) => getExperience(state));

    const [publish, { isLoading: isPublishLoading }] = isModelLayout ? usePublishModelMutation() : usePublishGarmentMutation();
    const [publishAll, { isLoading: isPublishAllLoading }] = isModelLayout ? usePublishAllModelMutation() : usePublishAllGarmentMutation();

    const isAnyLoading = isLoading || isPublishLoading || isPublishAllLoading;

    const [currentAction, setCurrentAction] = useState<PRODUCT_ACTIONS | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(category === 'model' ? 0 : -1);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Used to fix the delay between the state update and the query loading
    const [oldQueryResponse, setOldQueryResponse] = useState<GarmentResponse | ModelsResponse>();

    const [userHasModifiedPose, setUserHasModifiedPose] = useState<boolean>(false);

    const config = useAppSelector((state) => getConfig(state));

    const shouldCollapse = customHeader ? undefined : (isModelLayout && tabIndex === 0);

    const [queryString, setQueryString] = useState<string>('');

    // Use this instead of queryResponse so there is no blink
    const [fullProducts, setFullProducts] = useState<Garment[] | Model[] | null>(queryResponse ? queryResponse.items : null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalProduct, setCurrentTotalProduct] = useState<number | null>(null);
    const [currentTotalDedup, setCurrentTotalDedup] = useState<number | null>(null);

    const resetTotalValues = () => {
        setCurrentTotalProduct(null);
        setCurrentTotalDedup(null);
    };

    const gridRef = useRef<HTMLDivElement>(null);
    const filtersPanelRef = useRef<{resetLocalFilters:() => void}>(null);

    const lockedFilterList = useMemo(() => {
        const result = [];
        if (lockFrontPose) {
            result.push(category === 'model'
                ? { filterKey: 'pose', filterValue: 'FRONT' }
                : { filterKey: 'garment_pose', filterValue: 'FRONT' });
        }

        return result;
    }, [lockFrontPose, category]);

    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
    const selectAProduct = (id: string) => setSelectedProducts([...selectedProducts, { id }]);
    const selectAllProduct = () => {
        const tmpSelectedProduct: {id: string}[] = [];
        if (fullProducts) {
            fullProducts.forEach((product) => {
                if (isModel(product)) {
                    selectAProduct(product.model_id);
                    tmpSelectedProduct.push({ id: product.model_id });
                } else {
                    tmpSelectedProduct.push({ id: product.garment_id });
                    selectAProduct(product.garment_id);
                }
            });
        }

        return tmpSelectedProduct;
    };
    const deselectAProduct = (id: string) => {
        if (checkAll) {
            const allSelectedProduct = selectAllProduct();
            setCheckAll(false);

            return setSelectedProducts(allSelectedProduct.filter((product) => product.id !== id));
        }

        return setSelectedProducts(selectedProducts.filter((product) => product.id !== id));
    };
    const changeCheckAll = (newVal: boolean) => {
        if (!newVal) {
            setSelectedProducts([]);
        }
        setCheckAll(newVal);
    };

    // Those methods are used for the Filter Context to handle the selection outside the filter components
    const getSelectedFilters = (filters: FilterInContextType[]) => {
        const filterObject: Filters = {};
        filters.forEach((filter) => {
            filterObject[filter.filterKey] = filter.filterValue;
        });

        return filterObject;
    };

    // Method to add a filter in the array or edit the filter if it's already in the array
    const addFilterToArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => {
        let newFilters = [...filters];
        const duplicateFilterIndex = filters.findIndex((filter) => filter.filterKey === newFilter.filterKey);
        if (duplicateFilterIndex !== -1) {
            newFilters[duplicateFilterIndex] = newFilter;
        } else {
            newFilters = [...filters, newFilter];
        }

        return newFilters;
    };

    const removeFilterFromArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => {
        // ---- If the filter we want to remove is in the lockedFilterList we keep it ----
        if (lockedFilterList.find((lockedFilter) => lockedFilter.filterKey === newFilter.filterKey)) {
            return filters;
        }

        const newFilters = filters.filter(
            (filter) => filter.filterKey !== newFilter.filterKey && filter.filterValue !== newFilter.filterValue,
        );

        return newFilters;
    };

    // If the filter is pose then we set the userModifiedPose boolean to true
    const checkPoseFilter = (filter: FilterInContextType) => {
        if (filter.filterKey === 'pose') {
            setUserHasModifiedPose(true);
        }
    };

    const [selectedFilters, setSelectedFilters] = useState<FilterInContextType[]>([]);

    // We fetch the keys and values in a list and call the filterSelection method
    const fetchNewFilters = (updatedFilters: FilterInContextType[], query = '', page = 1, collapse = false, forceRefetch = false) => {
        const filters = getSelectedFilters(updatedFilters);
        setCurrentPage(page);
        dataQuery(filters, query, page, collapse ? 1 : null, forceRefetch);
    };

    const selectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1, collapse: boolean | null = null) => {
        checkPoseFilter(newFilter);
        // Need to control if key already exists
        const newFilters = addFilterToArray([...selectedFilters], newFilter);
        resetTotalValues();
        setSelectedFilters(newFilters);
        fetchNewFilters(newFilters, query, page, collapse !== null ? collapse : shouldCollapse);
    };

    const deselectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1, collapse: boolean | null = null) => {
        checkPoseFilter(newFilter);
        const newFilters = removeFilterFromArray([...selectedFilters], newFilter);
        resetTotalValues();
        setSelectedFilters(newFilters);
        fetchNewFilters(newFilters, query, page, collapse !== null ? collapse : shouldCollapse);
    };

    const deselectAll = () => {
        if (!isModelLayout) {
            setTabIndex(-1);
        }

        setUserHasModifiedPose(true);
        resetTotalValues();
        setSelectedFilters(lockedFilterList);
        fetchNewFilters(lockedFilterList, queryString, undefined, shouldCollapse);
    };

    // Update all the selectedFilters array and fetch the new data. It doesn't change the userModifedPose boolean
    const updateAllSelectedFilters = (newFilters: FilterInContextType[], query = queryString, page = 1, collapse: boolean | null = null) => {
        setSelectedFilters(newFilters);
        resetTotalValues();
        fetchNewFilters(newFilters, query, page, collapse !== null ? collapse : shouldCollapse);
    };

    // Called when we scroll the product grid and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80 || !queryResponse || isLoading) {
            return;
        }

        const apiCurrentPage = parseInt(queryResponse.current_page_number, 10);
        const apiNumitemsPerPage = parseInt(queryResponse.num_items_per_page, 10);

        // Prevent delay between fullProducts and queryResponse items
        if (fullProducts && fullProducts.length <= (currentPage - 1) * apiNumitemsPerPage) {
            return;
        }

        // Prevent loading 1 page that doesn't exist
        if ((apiCurrentPage * apiNumitemsPerPage) >= queryResponse.total_count) {
            return;
        }

        fetchNewFilters(selectedFilters, queryString, currentPage + 1);
    };

    const handleSearch = (input: string) => {
        if (timeoutSearchId) {
            clearTimeout(timeoutSearchId);
        }
        timeoutSearchId = setTimeout(() => {
            setQueryString(input);
            fetchNewFilters(selectedFilters, input, undefined, shouldCollapse);
        }, 300);
    };

    /**
     * Called when we want to change the tab.
     * Model: We change the collapse value according to the tab value
     * Garment: if we already have the Garment Type filter selected we deselect it. Otherwise we select the filter
     * @param newTabIndex The tab that we want to change to
     */
    const handleTabChange = (newTabIndex: number) => {
        setTabIndex(newTabIndex);
        if (!isModelLayout) {
            const foundFilter = selectedFilters.find((filter) => filter.filterValue === productTypes[newTabIndex].value.toUpperCase());
            if (foundFilter) {
                setTabIndex(-1);

                return deselectAFilter({ filterKey: 'garment_type', filterValue: productTypes[newTabIndex].value.toUpperCase() }, queryString);
            }

            return selectAFilter({
                filterKey: 'garment_type',
                filterLabel: productTypes[newTabIndex].label,
                filterValue: productTypes[newTabIndex].value.toUpperCase(),
            }, queryString);
        }

        // When clicking the model tab, we deselect the identity filter if it was previously selected
        if (newTabIndex === 0) {
            let newFilters = !userHasModifiedPose
                ? addFilterToArray([...selectedFilters], { filterKey: 'pose', filterValue: 'FRONT' })
                : [...selectedFilters];

            const identityFilter = newFilters.find((filter) => filter.filterKey === 'identity_id');
            if (identityFilter) {
                newFilters = removeFilterFromArray(newFilters, { filterKey: 'identity_id', filterValue: '' });
            }

            return updateAllSelectedFilters(newFilters, queryString, undefined, true);
        }

        // When clicking the pose tab, we deselect the pose filter if it was previously selected
        if (newTabIndex === 1) {
            const newFilters = removeFilterFromArray([...selectedFilters], { filterKey: 'pose', filterValue: 'FRONT' });

            return updateAllSelectedFilters(newFilters, queryString, undefined, false);
        }

        return fetchNewFilters(selectedFilters, queryString, undefined, newTabIndex === 0);
    };

    /**
     * Called when the See All button was pressed. We switch to the Pose tab with the right filter
     * @param selectedModel Model where the See all was clicked
     */
    const handleOnViewAllClick = (selectedModel: Model) => {
        setTabIndex(1);

        let newFilters = removeFilterFromArray([...selectedFilters], { filterKey: 'pose', filterValue: 'FRONT' });

        newFilters = addFilterToArray(newFilters, {
            filterKey: 'identity_id',
            filterLabel: selectedModel.identity_name,
            filterValue: selectedModel.identity_id,
        });

        updateAllSelectedFilters(newFilters, queryString, undefined, false);
    };

    const handleCreateOutfit = () => {
        if (!fullProducts) {
            return;
        }

        for (const product of fullProducts) {
            if (isModel(product)) {
                if (product.model_id === selectedProducts[0].id) {
                    if (product.pose !== outfitPoseStore) {
                        dispatch(resetOutfit());
                        dispatch(setOutfitPose(product.pose));
                    }

                    if (product.gender !== outfitGenderStore) {
                        dispatch(resetOutfit());
                        dispatch(setOutfitGender(product.gender));
                    }

                    dispatch(setModel({ id: product.model_id, url: product.image_url }));
                    dispatch(setPose({ id: product.model_id, url: product.image_url }));
                    break;
                }
            } else if (product.garment_id === selectedProducts[0].id) {
                if (product.garment_pose !== outfitPoseStore) {
                    dispatch(resetOutfit());
                    dispatch(setOutfitPose(product.garment_pose));
                }

                if (product.garment_gender !== outfitGenderStore) {
                    dispatch(resetOutfit());
                    dispatch(setOutfitGender(product.garment_gender));
                }

                const outfitObject = { id: product.garment_id, url: product.image_url };

                switch (product.garment_type) {
                    case 'TOP':
                        dispatch(setTop(outfitObject));
                        break;
                    case 'BOTTOM':
                        dispatch(setBottom(outfitObject));
                        break;
                    case 'DRESS':
                        dispatch(setDress(outfitObject));
                        break;
                    case 'OUTERWEAR':
                        dispatch(setOuterwear(outfitObject));
                        break;
                }
                break;
            }
        }

        navigate('/outfit');
    };

    const showErrorToast = () => {
        toast({
            isClosable: true,
            status: 'error',
            title: t(`actions.${currentAction}.error_message`, { count: 1, productType: category }),
        });
    };

    const callAction = (action: PRODUCT_ACTIONS) => {
        setCurrentAction(action);
        onOpen();
    };

    const onCloseModal = () => {
        setSelectedProducts([]);
        onClose();
    };

    React.useImperativeHandle(
        ref,
        () => ({
            resetData(keepFilter = false) {
                setFullProducts(null);
                if (!keepFilter) {
                    setSelectedFilters([]);
                    filtersPanelRef.current?.resetLocalFilters();
                    deselectAll();
                }
                resetTotalValues();
            },
        }),
    );
    const onSubmit = async () => {
        setCurrentPage(1);
        const filters = getSelectedFilters(selectedFilters);
        const filterData = {
            clientId,
            collapse: shouldCollapse ? 1 : 0,
            filters,
            query: queryString,
        };
        try {
            switch (currentAction) {
                case PRODUCT_ACTIONS.PUBLISH:
                    if (checkAll) {
                        await publishAll(
                            {
                                experienceId: selectedExperience?.id,
                                filterData,
                                publish: 1,
                            },
                        ).unwrap();
                    } else {
                        const allIds: string[] = [];
                        selectedProducts.forEach((product) => {
                            allIds.push(product.id);
                        });
                        await publish({ clientId, ids: allIds, publish: 1 }).unwrap();
                    }

                    break;
                case PRODUCT_ACTIONS.UNPUBLISH:
                    if (checkAll) {
                        await publishAll(
                            {
                                experienceId: selectedExperience?.id,
                                filterData,
                                publish: 0,
                            },
                        ).unwrap();
                    } else {
                        const allIds: string[] = [];
                        selectedProducts.forEach((product) => {
                            allIds.push(product.id);
                        });
                        await publish({ clientId, ids: allIds, publish: 0 }).unwrap();
                    }

                    break;
                default:
                    showErrorToast();
            }
            const totalModified: number | null = shouldCollapse ? currentTotalDedup : currentTotalProduct;
            const selectedCount = checkAll && totalModified ? totalModified : selectedProducts.length;
            toast({
                isClosable: true,
                status: 'success',
                title: t(
                    `actions.${currentAction}.success_message`,
                    {
                        count: selectedCount,
                        productType: category,
                    },
                ),
            });
        } catch (e) {
            showErrorToast();
        }
        fetchNewFilters(selectedFilters, queryString, undefined, shouldCollapse, true);
    };

    /**
     * Function looking for the product from selectedProducts in fullProducts to check the published State
     * @param published the published state we are looking for
     * @returns true if we found at least one element that has the same published state
     */
    const hasPublishedState = (published: boolean) => {
        let result = false;
        if (fullProducts) {
            for (const product of selectedProducts) {
                for (const allProduct of fullProducts) {
                    if (isModel(allProduct)) {
                        if (allProduct.model_id === product.id && allProduct.published === published) {
                            result = true;
                            break;
                        }
                        continue;
                    }
                    if (allProduct.garment_id === product.id && allProduct.published === published) {
                        result = true;
                        break;
                    }
                }
                if (result === true) {
                    break;
                }
            }
        }

        return result;
    };

    // We concat the data only if we fetch a page equals to the (already updated) current page
    useEffect(() => {
        // Need isLoading in this useEffect because the queryResponse doesn't change sometimes
        if (queryResponse && !isLoading) {
            const apiCurrentPage = parseInt(queryResponse.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setFullProducts((prev) => prev.concat(queryResponse.items));
            } else {
                setCurrentPage(1);
                setCurrentTotalProduct(queryResponse.total_count);
                setCurrentTotalDedup(queryResponse.total_dedup_count);
                setFullProducts(queryResponse.items);
            }
            setOldQueryResponse(queryResponse);
        }
    }, [queryResponse]);

    // Fix of tabIndex init when it doesn't change when category change
    useEffect(() => {
        if (category === 'model') {
            return setTabIndex(0);
        }

        return setTabIndex(-1);
    }, [category]);

    // Select by default the pose filter with FRONT as a value
    useEffect(() => {
        if (category === 'model') {
            return updateAllSelectedFilters([{ filterKey: 'pose', filterValue: 'FRONT' }]);
        }

        return updateAllSelectedFilters([{ filterKey: 'garment_pose', filterValue: 'FRONT' }]);
    }, []);

    const isQueryDifferent = queryResponse !== oldQueryResponse;

    return (
        <Box boxSize="full" h={hideContent ? 'auto' : 'full'} maxH="full">
            <VStack align="flex-start" boxSize="full" spacing={6}>
                {
                    customHeader || <HStack justify="space-between" minH={14} w="full">
                        <HStack>
                            <Text
                                color="gray.400"
                                fontWeight="semibold"
                                pr={8}
                                whiteSpace="nowrap">
                                {t('tabs.title', { productsCategory: category })}
                            </Text>
                            <ButtonGroup variant="tab-like">
                                {
                                    isModelLayout
                                        ? <>
                                            <Button
                                                isActive={tabIndex === 0}
                                                onClick={
                                                    () => handleTabChange(0)
                                                }
                                            >
                                                {t(`product_types.${PRODUCT_TYPES.MODEL.toLocaleLowerCase()}_other`)}
                                            </Button>
                                            <Button
                                                isActive={tabIndex === 1}
                                                onClick={
                                                    () => handleTabChange(1)
                                                }
                                            >
                                                {t(`product_types.${PRODUCT_TYPES.POSE.toLocaleLowerCase()}_other`)}
                                            </Button>
                                        </>
                                        : productTypes.map((productType, index) => (
                                            <Button
                                                isActive={tabIndex === index}
                                                key={productType.value}
                                                onClick={
                                                    () => handleTabChange(index)
                                                }
                                            >
                                                {t(`product_types.${productType.value.toLocaleLowerCase()}_other`)}
                                            </Button>
                                        ))
                                }
                            </ButtonGroup>
                        </HStack>

                        <Wrap justify="flex-end">
                            { selectedProducts.length === 1 && config?.platform_enable_outfit
                                && <Button
                                    leftIcon={<RepeatIcon boxSize={5} />}
                                    onClick={handleCreateOutfit}
                                    variant="white"
                                >
                                    {t('actions.create_outfit')}
                                </Button>
                            }
                            { (selectedProducts.length > 0 || checkAll) && (
                                <>
                                    {
                                        (hasPublishedState(true) || checkAll)
                                    && <Button
                                        onClick={() => callAction(PRODUCT_ACTIONS.UNPUBLISH)}
                                        variant="outline"
                                    >
                                        {t('actions.unpublish.label')}
                                    </Button>
                                    }
                                    {
                                        (hasPublishedState(false) || checkAll)
                                        && <Button
                                            onClick={() => callAction(PRODUCT_ACTIONS.PUBLISH)}
                                            variant="solid"
                                        >
                                            {t('actions.publish.label')}
                                        </Button>
                                    }

                                </>)
                            }
                        </Wrap>
                    </HStack>
                }

                {
                    !hideContent
                    && <>
                        <Divider mt="16px !important" />
                        <HStack boxSize="full" overflow="hidden" spacing={10}>
                            <>
                                <FiltersContext.Provider value={{ deselectAFilter, deselectAll, filters: selectedFilters, selectAFilter }}>
                                    <FiltersPanel
                                        filters={queryResponse?.facets}
                                        isLoading={(isAnyLoading || isQueryDifferent)}
                                        lockedFilters={lockedFilterList}
                                        onSearch={handleSearch}
                                        ref={filtersPanelRef}
                                        type={category}
                                    />
                                </FiltersContext.Provider>
                                {
                                    children || <ProductsContext.Provider
                                        value={{
                                            changeCheckAll,
                                            checkAll,
                                            deselectAProduct,
                                            selectAProduct,
                                            selectedProducts,
                                        }}
                                    >
                                        <ProductsGrid
                                            imagesRatio={
                                                config
                                                    ? `${(100 / (isModelLayout ? config.look_image_ratio : config.garment_image_ratio)).toString()}%`
                                                    : '120%'
                                            }
                                            isLoading={(isAnyLoading || isQueryDifferent)}
                                            onScroll={handleScroll}
                                            onViewAllClick={shouldCollapse ? handleOnViewAllClick : undefined}
                                            products={fullProducts}
                                            ref={gridRef}
                                            totalDedup={currentTotalDedup}
                                            totalNumber={currentTotalProduct}
                                        />
                                    </ProductsContext.Provider>
                                }
                            </>
                        </HStack>
                    </>

                }

            </VStack>
            <Modal
                cancelButtonLabel={t('actions.confirm_modal.cancel_label')}
                confirmButtonLabel={t('actions.confirm_modal.submit_label')}
                isOpen={isOpen}
                onCancel={showErrorToast}
                onClose={onCloseModal}
                onConfirm={onSubmit}
                title={t('actions.confirm_modal.title')}
            >
                <Text>{t(
                    `actions.${currentAction}.confirm_informations`,
                    { count: selectedProducts.length, productType: category },
                )}</Text>
            </Modal>
        </Box>
    );
});

export default ProductsLayout;
