import { Center, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spinner, Switch, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forceCheck } from 'react-lazyload';
import { useDispatch } from 'react-redux';

import { Filters } from '../components/filters/filtersTypes';
import LookGarmentCard from '../components/products/LookGarmentCard';
import ProductsLayout from '../components/products/ProductsLayout';
import { invalidateGetGarmentsAction, useGetGarmentsQuery } from '../services/api/api-garment';
import { getClient, getExperience } from '../services/store/slices/sessionSlice';
import { useAppSelector } from '../services/store/store';
import { FacetTerms, FacetTermsData, Garment, GarmentQueryInput } from '../types/api-types';
import { PRODUCTS_CATEGORIES, PRODUCTS_LOCALES } from '../utils/constants';

let shouldRefresh = false;

export default function Looks() {
    const { t } = useTranslation(PRODUCTS_LOCALES, { keyPrefix: 'grid' });

    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const [productTypes, setProductTypes] = React.useState<FacetTermsData[]>([]);
    const [queryInputObject, setQueryInputObject] = React.useState<GarmentQueryInput>({
        clientId: currentClient ? currentClient.id : '',
        experienceId: selectedExperience ? selectedExperience.id : undefined,
        filters: { garment_pose: 'FRONT' },
        looks: 1,
    });
    const { data: garmentResponse, isFetching: isLoadingGarments } = useGetGarmentsQuery(queryInputObject);
    const dispatch = useDispatch();

    const defaultProductsPerRow = 4;
    const maxProductsPerRow = 6;
    const minProductsPerRow = 3;

    const [productsPerRow, setProductsPerRow] = useState<number>(defaultProductsPerRow);
    const [fullProducts, setFullProducts] = useState<Garment[] | null>(garmentResponse ? garmentResponse.items : null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [mainImageType, setMainImageType] = useState<'garment' | 'look'>('garment');

    const updateProductsPerRow = (value: number) => {
        setProductsPerRow(minProductsPerRow + maxProductsPerRow - value);
        forceCheck();
    };

    function useGarmentQuery(filters: Filters, query = '', page = 1, collapse = null, forceRefetch = false) {
        if (currentClient) {
            setQueryInputObject({ clientId: currentClient.id, collapse, experienceId: selectedExperience?.id, filters, looks: 1, page, query });
        }
        if (forceRefetch) {
            shouldRefresh = true;
        }
    }

    // Called when we scroll the product grid and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80 || !garmentResponse || isLoadingGarments) {
            return;
        }

        const apiCurrentPage = parseInt(garmentResponse.current_page_number, 10);
        const apiNumitemsPerPage = parseInt(garmentResponse.num_items_per_page, 10);

        // Prevent delay between fullProducts and queryResponse items
        if (fullProducts && fullProducts.length <= (currentPage - 1) * apiNumitemsPerPage) {
            return;
        }

        // Prevent loading 1 page that doesn't exist
        if ((apiCurrentPage * apiNumitemsPerPage) >= garmentResponse.total_count) {
            return;
        }

        setCurrentPage(currentPage + 1);
        useGarmentQuery(queryInputObject.filters || {}, queryInputObject.query, currentPage + 1);
    };

    useEffect(() => {
        if (shouldRefresh) {
            dispatch(invalidateGetGarmentsAction);
        }
        shouldRefresh = false;
    }, [queryInputObject]);

    useEffect(() => {
        if (productTypes.length === 0 && garmentResponse && garmentResponse.facets) {
            const foundData = Object.keys(garmentResponse.facets).find((facetKey) => facetKey === 'garment_type');
            if (foundData) {
                setProductTypes((garmentResponse.facets.garment_type as FacetTerms).data);
            }
        }
    }, [garmentResponse]);

    // We concat the data only if we fetch a page equals to the (already updated) current page
    useEffect(() => {
        // Need isLoading in this useEffect because the queryResponse doesn't change sometimes
        if (garmentResponse && !isLoadingGarments) {
            const apiCurrentPage = parseInt(garmentResponse.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setFullProducts((prev) => prev.concat(garmentResponse.items));
            } else {
                setCurrentPage(1);
                setFullProducts(garmentResponse.items);
            }
        }
    }, [garmentResponse]);

    return (
        <ProductsLayout
            category={PRODUCTS_CATEGORIES.GARMENT}
            clientId={currentClient?.id || ''}
            dataQuery={useGarmentQuery}
            isLoading={isLoadingGarments}
            lockFrontPose={true}
            productTypes={productTypes}
            queryResponse={garmentResponse}
        >
            <VStack boxSize="full">
                <HStack justify="space-between" pr={12} width="full">
                    <HStack>
                        <Switch
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                            onChange={() => setMainImageType(mainImageType === 'garment' ? 'look' : 'garment')}
                            value={mainImageType}
                        >
                            Look
                        </Switch>
                    </HStack>
                    {
                        garmentResponse && <HStack>
                            <Text color="gray.400">{`${t('search_results')}: `}</Text>
                            <Text fontWeight="bold">{
                                garmentResponse.total_dedup_count
                                    ? `${garmentResponse.total_dedup_count} (${garmentResponse.total_count})`
                                    : garmentResponse.total_count
                            }
                            </Text>
                            {isLoadingGarments && <Spinner />}
                        </HStack>
                    }

                    <HStack>
                        <Text color="gray.400" mr={4}>{`${t('image_sizes')}: `}</Text>
                        <Slider
                            defaultValue={minProductsPerRow + maxProductsPerRow - defaultProductsPerRow}
                            max={maxProductsPerRow}
                            min={minProductsPerRow}
                            onChange={updateProductsPerRow}
                            step={1}
                            variant="gray"
                            w={120}
                        >
                            <SliderTrack h={0.5}>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </HStack>
                </HStack>
                {
                    fullProducts && <Wrap boxSize="full" onScroll={handleScroll} overflowY="auto" pb={6} pr={8} spacing={2}>
                        {
                            fullProducts.map((product, index) => (
                                <WrapItem key={index} w={`calc(100% / ${productsPerRow} - 8px)`}>
                                    <Center boxSize="full">
                                        <LookGarmentCard garment={product} mainImageType={mainImageType}/>
                                    </Center>
                                </WrapItem>

                            ))
                        }
                    </Wrap>
                }
            </VStack>

        </ProductsLayout>
    );
}
